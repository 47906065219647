import { useHistory } from "react-router-dom";

interface RedirectPageProps {
	redirectUrl: string;
}

const RedirectPage = (props: RedirectPageProps) => {
	useHistory().push(props.redirectUrl);
	return <>Yönlendiriliyor...</>;
};

export default RedirectPage;
