import { Button } from "primereact/button";
import { CSSProperties, ReactNode } from "react";

interface AvkButtonProps {
	id?: string;
	name?: string;
	label?: string;
	icon?: ReactNode;
	iconPosition?: "top" | "bottom" | "left" | "right";
	size?: "small" | "large";
	className?: string;
	style?: CSSProperties;
	useText?: boolean;
	fullwidth?: boolean;
	type?: "button" | "submit" | "reset";
	severity?: "secondary" | "success" | "info" | "warning" | "danger" | "help";
	disabled?: boolean;
	rounded?: boolean;
	outlined?: boolean;
	link?: boolean;
	tabIndex?: number;
	onClick?: (event: any) => void;
}

const AvkButton = (props: AvkButtonProps) => {
	const useText = props.useText ?? true;
	const fullwidthClass = props.fullwidth
		? "w-full flex align-items-center justify-content-center" //tam genişlikte text ve iconları ortalamak için
		: "";
	const className = props.className ?? "";

	return (
		<Button
			id={props.id}
			name={props.name}
			type={props.type}
			label={props.label}
			className={`${fullwidthClass} ${className}`}
			style={props.style}
			onClick={props.onClick}
			icon={props.icon}
			iconPos={props.iconPosition ?? "left"}
			text={!useText}
			size={props.size ?? "large"}
			severity={props.severity}
			disabled={props.disabled}
			rounded={props.rounded}
			outlined={props.outlined}
			tabIndex={props.tabIndex ?? 0}
			link={props.link}
		/>
	);
};

export default AvkButton;
