import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { addNotification } from "../../../redux/slices/notification-slice";
import { store } from "../../../redux/store";
import {
	checkIsShowEmailValidationResultFromLocalStorage,
	removeShowEmailValidationResultFromLocalStorage,
} from "../../../utils/AuthFunctions";

const ValidationResultPage = () => {
	const history = useHistory();
	const { result }: any = useParams();

	useEffect(() => {
		let redirectPath = "/";

		if (result === "fail") {
			store.dispatch(
				addNotification({
					id: crypto.randomUUID(),
					title: "Doğrulama Başarısız",
					message:
						"E-posta doğrulama işleminiz sırasında bir hata ile karşılaşıldı.",
					type: "error",
					showTime: 2500,
				})
			);
		} else if (
			result === "success" &&
			checkIsShowEmailValidationResultFromLocalStorage()
		) {
			removeShowEmailValidationResultFromLocalStorage();
			redirectPath = "/profile";
			store.dispatch(
				addNotification({
					id: crypto.randomUUID(),
					title: "Doğrulama Başarılı",
					message: "E-posta doğrulama işleminiz başarı ile tamamlandı.",
					type: "success",
					showTime: 2500,
				})
			);
		}

		history.push(redirectPath);
	}, []); // eslint-disable-line

	return <></>;
};

export default ValidationResultPage;
