import AvkCard from "../AvkCard";
import { AvkDataGridViewData } from "../AvkDataGridView/AvkDataViewObjects";

import AvkScrollPanel from "../AvkScrollPanel";

interface AvkCardProps {
	data: AvkDataGridViewData;
	style?: React.CSSProperties;
	className?: string;
}

const AvkDataGridViewTemplate = (props: AvkCardProps) => {
	return (
		<div
			className={`w-12 md:w-6 lg:w-4 xl:w-3 p-3 ${
				props.className ? props.className : ""
			}`}
			key={props.data.id + "product-detail-main-div"}
			style={props.style}
		>
			<AvkCard
				shadow={4}
				className="bg-gray-100 h-auto cursor-pointer min-h-full"
				key={props.data.id + "product-detail-card"}
				title={
					<div
						style={{
							fontSize: "1.5vb",
							height: 50,
						}}
					>
						{props.data.title}
					</div>
				}
				header={props.data.header}
				footer={
					<div
						className={props.data.footerClassName}
						style={{
							height: 36,
							borderTop: "1px solid #E0E0E0",
						}}
					>
						{props.data.footerContent}
					</div>
				}
				onClick={() => {
					props.data.onClick?.(props.data.id);
				}}
			>
				<AvkScrollPanel
					height="60px"
					key={props.data.id + "product-detail-scroll-panel"}
				>
					{props.data.content}
				</AvkScrollPanel>
			</AvkCard>
		</div>
	);
};

export default AvkDataGridViewTemplate;
