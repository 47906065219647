import { PageableResponse } from "../../../dto/response/PageableResponse";
import {
	ParticipantProgramDTO,
	ParticipantProgramRegisteredInfo,
	ProgramDTO,
	ProgramDetailDTO,
} from "../../../dto/response/ProgramDTO";
import SuccessResponseModel from "../../../dto/response/SuccessResponseModel";
import { ApiClient } from "../../ApiConfig";

let client = ApiClient.getInstance();

const programClient = {
	getProgram(page: number, size: number) {
		return client.get<PageableResponse<ProgramDTO>>("/programs", {
			params: {
				page: page,
				size: size,
			},
		});
	},
	getUserPrograms() {
		return client.get<ParticipantProgramDTO[]>("/programs/participant");
	},
	getProgramDetails(programId: number) {
		return client.get<ProgramDetailDTO>(`/programs/${programId}`);
	},
	registerProgram(programId: number) {
		return client.post<SuccessResponseModel>(`/programs`, {
			programId: programId,
		});
	},
	getParticipantProgramRegisterInfo(programId: number) {
		return client.get<ParticipantProgramRegisteredInfo>(
			`/programs/registered-info`,
			{
				params: {
					"program-id": programId,
				},
			}
		);
	},
};
export default programClient;
