import { createSlice } from "@reduxjs/toolkit";
import {
	removeLoginFromLocalStorage,
	setAsLoginToLocalStorage,
} from "../../utils/AuthFunctions";

export type UserSliceObject = {
	userInfo: {
		name: string;
		surname: string;
		email: string;
		emailValid: boolean;
		profileInfoValid: boolean;
	};
	userLoggedIn: boolean;
	openLoginForm: boolean;
};

export const userSlice = createSlice({
	name: "user",
	initialState: {
		userInfo: {
			name: "",
			surname: "",
			email: "",
			emailValid: true,
			profileInfoValid: true,
		},
		userLoggedIn: false,
		openLoginForm: false,
	} as UserSliceObject,
	reducers: {
		setUserLoggedIn: (state, action) => {
			state.userLoggedIn = true;
			state.userInfo = action.payload;
			setAsLoginToLocalStorage();
		},
		setUserInfo: (state, action) => {
			state.userInfo = action.payload;
		},
		setUserLoggedOut: (state) => {
			state.userInfo.name = "";
			state.userInfo.surname = "";
			state.userInfo.email = "";
			state.userInfo.emailValid = false;
			state.userInfo.profileInfoValid = false;
			state.userLoggedIn = false;
			removeLoginFromLocalStorage();
		},
		setOpenUserForm: (state, action) => {
			state.openLoginForm = action.payload;
		},
	},
});

export const {
	setUserInfo,
	setOpenUserForm,
	setUserLoggedIn,
	setUserLoggedOut,
} = userSlice.actions;
