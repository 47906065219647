import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { setRedirectTo } from "../redux/slices/auth-slice";
import {
	addNotification,
	removeNotificationAfterLogOut,
} from "../redux/slices/notification-slice";
import { setUserLoggedOut } from "../redux/slices/user-slice";
import { store } from "../redux/store";

const baseURL = process.env.REACT_APP_BASE_URL;
const getClient = () => {
	const client = axios.create({
		baseURL: baseURL,
		withCredentials: true,
		responseType: "json",
		headers: {
			"Content-Type": "application/json",
		},
	});

	client.interceptors.request.use(
		(requestConfig) => requestConfig,
		(requestError) => {
			return Promise.reject(requestError);
		}
	);

	client.interceptors.response.use(
		(response) => {
			return response;
		},
		(error) => {
			if (error.response.status === 403) {
				store.dispatch(removeNotificationAfterLogOut());
				store.dispatch(setUserLoggedOut());
				store.dispatch(setRedirectTo("/"));
			}
			if (error.response.data.code) {
				store.dispatch(
					addNotification({
						id: crypto.randomUUID(),
						title: "Hata Kodu: " + error.response.data.code,
						message: error.response.data.message,
						type: "error",
					})
				);
			}

			return Promise.reject(error);
		}
	);

	return client;
};

class ApiClient {
	private static instance: ApiClient;
	client: AxiosInstance;
	private constructor() {
		this.client = getClient();
	}

	static getInstance() {
		if (!ApiClient.instance) {
			ApiClient.instance = new ApiClient();
		}
		return ApiClient.instance;
	}

	get<T = any>(url: string, conf?: AxiosRequestConfig) {
		return this.client.get<T>(url, conf);
	}

	getBlob(url: string, conf?: AxiosRequestConfig) {
		return this.client.get<Blob>(url, {
			...conf,
			responseType: "blob",
		});
	}

	delete<T>(url: string, conf?: AxiosRequestConfig) {
		return this.client.delete<T>(url, conf);
	}

	head<T>(url: string, conf?: AxiosRequestConfig) {
		return this.client.head<T>(url, conf);
	}

	options<T>(url: string, conf?: AxiosRequestConfig) {
		return this.client.options<T>(url, conf);
	}

	post<T>(url: string, data: any, conf?: AxiosRequestConfig) {
		return this.client.post<T>(url, data, conf);
	}

	put<T>(url: string, data: any, conf?: AxiosRequestConfig) {
		return this.client.put<T>(url, data, conf);
	}

	patch<T>(url: string, data: any, conf?: AxiosRequestConfig) {
		return this.client.patch<T>(url, data, conf);
	}

	setAuthHeader(token: string) {
		if (token && token !== "undefined") {
			this.client.defaults.headers.common["Authorization"] =
				"Bearer " + token;
		}
	}
}

export { ApiClient };
