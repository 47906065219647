import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { UserSliceObject } from "../../../redux/slices/user-slice";
import { checkIsLoginFromLocalStorage } from "../../../utils/AuthFunctions";

interface AvkPrivateRouteProps {
	path: string;
	children: JSX.Element;
	redirectPath?: string;
	exact?: boolean;
	public?: boolean;
}

const AvkPrivateRoute = (props: AvkPrivateRouteProps) => {
	const userObject: UserSliceObject = useSelector((props: any) => props.user);

	const openPageIfProfileInfoValid = (): ReactNode => {
		if (checkIsLoginFromLocalStorage() !== userObject.userLoggedIn) {
			return <></>;
		} else if (!userObject.userLoggedIn && props.public) {
			return props.children;
		}

		const unvalidatedPath = "/profile";
		return userObject.userInfo.profileInfoValid ||
			props.path === unvalidatedPath ? (
			props.children
		) : (
			<Redirect
				to={{
					pathname: unvalidatedPath,
				}}
			/>
		);
	};

	return (
		<Route exact={props.exact} path={props.path}>
			{props.public === false &&
			checkIsLoginFromLocalStorage() ===
				(props.redirectPath !== undefined) ? (
				<Redirect
					to={{
						pathname: props.redirectPath ?? "/",
					}}
				/>
			) : (
				openPageIfProfileInfoValid()
			)}
		</Route>
	);
};

export default AvkPrivateRoute;
