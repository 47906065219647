import { DataView } from "primereact/dataview";
import AvkDataTemplate from "../AvkDataGridViewTemplate";
import { AvkDataGridViewData } from "./AvkDataViewObjects";

interface AvkDataGridViewProps {
	data: AvkDataGridViewData[];
	style?: React.CSSProperties;
	className?: string;
}

const AvkDataGridView = (props: AvkDataGridViewProps) => {
	return (
		<DataView
			layout="grid"
			style={props.style}
			value={props.data}
			className={props.className}
			itemTemplate={(data: AvkDataGridViewData) => (
				<AvkDataTemplate
					data={{
						id: data.id,
						title: data.title,
						header: data.header,
						content: data.content,
						onClick: (id: number) => {
							data.onClick?.(id);
						},
						footerClassName: data.footerClassName,
						footerContent: data.footerContent,
					}}
					className="h-full"
				/>
			)}
		/>
	);
};

export default AvkDataGridView;
