import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type SiteObject = {
	logoBase64: string;
	title: string;
	phone: string;
	kvkkUrl: string;
	courseApplyActive: boolean;
	moreInformationUrl: string;
};

export const siteSlice = createSlice({
	name: "site",
	initialState: {
		logoBase64: "",
		title: "",
		phone: "",
		kvkkUrl: "",
		courseApplyActive: false,
		moreInformationUrl: "",
	} as SiteObject,
	reducers: {
		setSiteInfo: (state, action: PayloadAction<SiteObject>) => {
			state.logoBase64 = action.payload.logoBase64;
			state.title = action.payload.title;
			state.phone = action.payload.phone;
			state.kvkkUrl = action.payload.kvkkUrl;
			state.courseApplyActive = action.payload.courseApplyActive;
			state.moreInformationUrl = action.payload.moreInformationUrl;
		},
	},
});

export const { setSiteInfo } = siteSlice.actions;
