import * as yup from "yup";
import {
	checkTckn,
	isNullOrUndefinedOrEmpty,
} from "../../utils/CommonFunctions";

const tcknOrPassportErrorMessage =
	"TC Kimlik No ya da Pasaport No alanlarından birini doldurunuz.";

export const ProfileInformationValidation = yup.object({
	governmentId: yup
		.string()
		.test(
			"tcknOrPassportNumberRequired",
			tcknOrPassportErrorMessage,
			(value, context) => {
				const isTcknEmpty = isNullOrUndefinedOrEmpty(value ?? "");
				if (
					(!isTcknEmpty && !checkTckn(value as string)) ||
					(isTcknEmpty &&
						isNullOrUndefinedOrEmpty(context.parent.pasaportId))
				) {
					return false;
				}
				return true;
			}
		),
	pasaportId: yup
		.string()
		.test(
			"passportNumberOrTcknRequired",
			tcknOrPassportErrorMessage,
			(value, context) => {
				if (
					isNullOrUndefinedOrEmpty(value ?? "") &&
					isNullOrUndefinedOrEmpty(context.parent.governmentId)
				) {
					return false;
				}
				return true;
			}
		),
	email: yup
		.string()
		.email()
		.required("Lütfen e-posta adresinizi doldurunuz."),
	name: yup.string().required("Lütfen ad bilginizi doldurunuz."),
	surname: yup.string().required("Lütfen soyad bilginizi doldurunuz."),
});

export const ProfileInfoPasswordValidation = yup.object({
	password: yup
		.string()
		.min(6)
		.max(24)
		.required("Lütfen bir parola belirleyiniz."),
	passwordRepeat: yup
		.string()
		.min(6)
		.max(24)
		.test(
			"passwordsShouldMatch",
			"Parolalar eşleşmiyor. Lütfen parolanızı kontrol ediniz.",
			(value, context) => {
				if (isNullOrUndefinedOrEmpty(value ?? "")) {
					return false;
				}
				return value === context.parent.password;
			}
		),
	currentPassword: yup.string().when("isPasswordValidationStep", {
		is: true,
		then: (shema) => shema.required("Lütfen parolanızı giriniz."),
		otherwise: (schema) => schema.notRequired(),
	}),
});
