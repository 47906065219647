import { ApiClient } from "../../ApiConfig";

let client = ApiClient.getInstance();

const siteClient = {
	getSiteInformation() {
		return client.get("/site/information");
	},
	getDefaultProfilePicture() {
		return client.getBlob("/site/profile-picture/default");
	},
};

export default siteClient;
