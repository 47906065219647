import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import EventIcon from "@mui/icons-material/Event";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import PasswordIcon from "@mui/icons-material/Password";
import PhoneIcon from "@mui/icons-material/Phone";
import PlaceIcon from "@mui/icons-material/Place";
import PortraitIcon from "@mui/icons-material/Portrait";
import RepeatIcon from "@mui/icons-material/Repeat";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useFormik } from "formik";
import { ReactNode, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { participantClient } from "../../api/services";
import validationClient from "../../api/services/validation";
import AvkButton from "../../components/avk-components/AvkButton";
import AvkCalender from "../../components/avk-components/AvkCalendar/AvkCalendar";
import AvkCard from "../../components/avk-components/AvkCard";
import AvkDialog from "../../components/avk-components/AvkDialog";
import AvkImage from "../../components/avk-components/AvkImage";
import AvkImageCropDialog from "../../components/avk-components/AvkImageCropDialog";
import AvkInputText from "../../components/avk-components/AvkInputText";
import AvkTooltip from "../../components/avk-components/AvkTooltip";
import AccountInformationFullResponse from "../../dto/response/AccountInformationResponse";
import { UpdateAccountFullResponse } from "../../dto/response/UpdateAccountResponse";
import { addNotification } from "../../redux/slices/notification-slice";
import { setUserInfo } from "../../redux/slices/user-slice";
import { store } from "../../redux/store";
import {
	getBase64FromImageUrl,
	getImageSrcUrlFromBase64String,
	isNullOrUndefinedOrEmpty,
} from "../../utils/CommonFunctions";
import {
	ProfileInfoPasswordValidation,
	ProfileInformationValidation,
} from "./ProfileValidation";

type UserInformationObject = {
	governmentId: string;
	pasaportId: string;
	email: string;
	phone: string;
	name: string;
	surname: string;
	birthDate: Date;
	birthPlace: string;
	tcknValid: boolean;
	emailValid: boolean;
	// ---
	fatherName: string;
	workPhone: string;
	job: string;
	gender: string;
	education: string;
	grantedBy: string;
	address: string;
	explanation: string;
	picture: Blob;
};

type UserChangePasswordObject = {
	password: string;
	passwordRepeat: string;
	currentPassword: string;
	isPasswordValidationStep: boolean;
};

type CropperProps = {
	show: boolean;
	image: string;
};

const ProfilePage = () => {
	const refInput = useRef<HTMLInputElement>(null);

	const history = useHistory();

	const [cropperProps, setCropperProps] = useState<CropperProps>({
		show: false,
		image: "",
	});

	const [profilePictureBase64, setProfilePictureBase64] = useState<string>();
	const [profilePicture, setProfilePicture] = useState<string>();

	const [userProfileInfo, setUserProfileInfo] =
		useState<UserInformationObject>();

	useEffect(() => {
		participantClient
			.getAccountInformation()
			.then(({ data }: AccountInformationFullResponse) => {
				const url = getImageSrcUrlFromBase64String(
					data.profilePicture.toString(),
					"image/jpeg"
				);
				getBase64FromImageUrl(url, setProfilePictureBase64);
				setProfilePicture(url);
				setUserProfileInfo({
					governmentId: data.tckn ?? "",
					pasaportId: data.passportNumber ?? "",
					email: data.email,
					phone: data.phoneNumber,
					name: data.name,
					surname: data.surname,
					birthDate: data.birthDate ? new Date(data.birthDate) : undefined,
					birthPlace: data.birthPlace,
					tcknValid: data.tcknValid,
					emailValid: data.emailValid,
				} as UserInformationObject);
			});
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: userProfileInfo ?? ({} as UserInformationObject),
		validationSchema: ProfileInformationValidation,
		validateOnBlur: true,
		validateOnChange: true,
		validateOnMount: true,
		onSubmit: () => {
			participantClient
				.updateAccount({
					profilePictureBase64: profilePictureBase64 ?? "",
					tckn: formik.values.governmentId,
					passportNumber: formik.values.pasaportId,
					email: formik.values.email,
					phoneNumber: formik.values.phone,
					name: formik.values.name,
					surname: formik.values.surname,
					birthDate: formik.values.birthDate,
					birthPlace: formik.values.birthPlace,
				})
				.then(({ data }: UpdateAccountFullResponse) => {
					store.dispatch(
						setUserInfo({
							name: data.name,
							surname: data.surname,
							email: data.email,
							emailValid: data.emailValid,
							profileInfoValid: data.profileInfoValid,
						})
					);
					store.dispatch(
						addNotification({
							id: crypto.randomUUID(),
							title: "Başarılı",
							message: "Kullanıcı bilgileriniz başarıyla güncellendi.",
							type: "success",
						})
					);
					history.go(0);
				});
		},
	});

	useEffect(() => {
		Object.keys(formik.errors).forEach((key) => {
			formik.setFieldTouched(key, true, false);
		});
	}, [formik.errors]); // eslint-disable-line react-hooks/exhaustive-deps

	const formikPassword = useFormik({
		initialValues: {
			password: "",
			passwordRepeat: "",
			currentPassword: "",
			isPasswordValidationStep: false,
		} as UserChangePasswordObject,
		validationSchema: ProfileInfoPasswordValidation,
		validateOnBlur: true,
		validateOnChange: true,
		onSubmit: () => {
			participantClient
				.updatePassword({
					password: formikPassword.values.password,
					currentPassword: formikPassword.values.currentPassword,
				})
				.then(() => {
					store.dispatch(
						addNotification({
							id: crypto.randomUUID(),
							title: "Başarılı",
							message: "Parolanız başarıyla güncellendi.",
							type: "success",
						})
					);
				});
			setShowUpdatePasswordConfirmDialog(false);
		},
	});

	const setShowUpdatePasswordConfirmDialog = (show: boolean) => {
		formikPassword.setFieldValue("isPasswordValidationStep", show);
	};

	const getEmailTextFieldIcon = (): ReactNode => {
		if (isNullOrUndefinedOrEmpty(formik.values.email)) {
			return undefined;
		}
		return formik.values.emailValid ? (
			<AvkTooltip
				matchingClassName="verification-email-tooltip"
				content={
					<VerifiedIcon
						className="z-5 verification-email-tooltip"
						fontSize="small"
						color="primary"
					/>
				}
				tooltipContent="Doğrulanmış E-posta"
			/>
		) : (
			<AvkTooltip
				matchingClassName="verification-email-tooltip"
				content={
					<ForwardToInboxIcon
						className="z-5 cursor-pointer verification-email-tooltip"
						fontSize="small"
						color="error"
						onClick={() => {
							validationClient.sendEmailValidation();
						}}
					/>
				}
				tooltipContent="Doğrulama epostasını göndermek için tıklayınız."
			/>
		);
	};

	const gridClassNameLeft = "col-offset-0 col-12 lg:col-offset-1 lg:col-5";
	const gridClassNameRight = "col-offset-0 col-12 lg:col-5";
	const gridClassNameButton =
		"text-center col-offset-0 col-12 lg:col-offset-8 lg:col-3";
	return (
		<>
			<div className="flex align-items-center justify-content-center flex-column h-full w-full">
				<AvkCard>
					<form onSubmit={formik.handleSubmit}>
						<div className="grid">
							<div className="col-12 text-center">
								<span className="font-bold text-3xl md:text-5xl">
									Kullanıcı Bilgileri
								</span>
							</div>
							<div className={gridClassNameLeft + " pb-0"}>
								<div className="grid my-0 pb-0">
									<div
										className="col-fixed mt-5 pb-0 pr-0 mb-0 relative mr-5"
										style={{ width: "88px" }}
									>
										<AvkImage
											src={profilePicture}
											width="85"
											height="116"
											className="mb-0 image-edit"
											imageStyle={{
												alignSelf: "center",
											}}
											onClick={() => {
												refInput.current?.click();
											}}
										/>
										<i className="pi pi-pencil absolute image-edit-icon top-50 left-50" />
										<input
											ref={refInput}
											className="hidden"
											type="file"
											accept="image/*"
											value={undefined}
											onClick={(e) => {
												e.currentTarget.value = "";
											}}
											onChange={(event: any) => {
												setCropperProps({
													show: true,
													image: URL.createObjectURL(
														event.target.files[0]
													),
												});
											}}
										/>
									</div>
									<div className="col pb-0 pl-0 mb-0">
										<div className="col-12 pl-0 pr-0 pt-0 mb-0">
											<AvkInputText
												fullwidth
												id="name"
												name="name"
												label="Ad"
												onBlur={formik.handleBlur}
												onChange={formik.handleChange}
												value={formik.values.name}
												groupIconElement={<FormatColorTextIcon />}
												error={
													formik.touched.name &&
													Boolean(formik.errors.name)
												}
											/>
										</div>
										<div className="col-12 pl-0 pr-0">
											<AvkInputText
												fullwidth
												id="surname"
												name="surname"
												label="Soyad"
												onBlur={formik.handleBlur}
												onChange={formik.handleChange}
												value={formik.values.surname}
												groupIconElement={<FormatColorTextIcon />}
												error={
													formik.touched.surname &&
													Boolean(formik.errors.surname)
												}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className={gridClassNameRight + " pb-0"}>
								<div className="grid my-0 pb-0">
									<div className="col-12 mb-0">
										<AvkInputText
											fullwidth
											id="governmentId"
											name="governmentId"
											label="TC Kimlik No"
											onBlur={formik.handleBlur}
											onChange={formik.handleChange}
											value={formik.values.governmentId}
											disabled={userProfileInfo?.tcknValid}
											groupIconElement={<FingerprintIcon />}
											error={
												formik.touched.governmentId &&
												Boolean(formik.errors.governmentId)
											}
											icon={
												formik.values.tcknValid ? (
													<AvkTooltip
														matchingClassName="verification-tckn-tooltip"
														content={
															<VerifiedIcon
																className="z-5 verification-tckn-tooltip"
																fontSize="small"
																color="primary"
															/>
														}
														tooltipContent="Doğrulanmış TC Kimlik Numarası"
													/>
												) : undefined
											}
										/>
									</div>
									<div className="col-12 mb-0">
										<AvkInputText
											fullwidth
											id="pasaportId"
											name="pasaportId"
											label="Pasaport No"
											onBlur={formik.handleBlur}
											onChange={formik.handleChange}
											value={formik.values.pasaportId}
											groupIconElement={<PortraitIcon />}
											error={
												formik.touched.pasaportId &&
												Boolean(formik.errors.pasaportId)
											}
										/>
									</div>
								</div>
							</div>
							<div className={gridClassNameLeft}>
								<AvkInputText
									fullwidth
									id="email"
									name="email"
									label="E-posta"
									className="email-validation-tooltip"
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									value={formik.values.email}
									groupIconElement={<AlternateEmailIcon />}
									error={
										formik.touched.email &&
										Boolean(formik.errors.email)
									}
									icon={getEmailTextFieldIcon()}
								/>
							</div>
							<div className={gridClassNameRight}>
								<AvkInputText
									fullwidth
									id="phone"
									name="phone"
									label="Telefon Numarası"
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									value={formik.values.phone}
									groupIconElement={<PhoneIcon />}
								/>
							</div>
							<div className={gridClassNameLeft}>
								<AvkCalender
									fullwidth
									id="birthDate"
									name="birthDate"
									label="Doğum Tarihi"
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									value={formik.values.birthDate}
									dateFormat="yy-mm-dd"
									groupIconElement={<EventIcon />}
								/>
							</div>
							<div className={gridClassNameRight}>
								<AvkInputText
									fullwidth
									id="birthPlace"
									name="birthPlace"
									label="Doğum Yeri"
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									value={formik.values.birthPlace}
									groupIconElement={<PlaceIcon />}
								/>
							</div>
							<div className={gridClassNameButton}>
								<AvkButton
									fullwidth
									id="btnUpdateUser"
									name="btnUpdateUser"
									label="KAYDET"
									type="submit"
									className="mt-3"
								/>
							</div>
						</div>
					</form>
					<div className="grid mt-4">
						<div className={gridClassNameLeft}>
							{/* 
												FIXME: Update password rules !.. 
												Create a component for profile and register page
										*/}
							<AvkTooltip
								showOnFocus
								matchingClassName="password-tooltip"
								content={
									<AvkInputText
										password
										fullwidth
										id="password"
										name="password"
										label="Parola"
										className="password-tooltip"
										onBlur={formikPassword.handleBlur}
										onChange={formikPassword.handleChange}
										value={formikPassword.values.password}
										groupIconElement={<PasswordIcon />}
										error={
											formikPassword.touched.password &&
											Boolean(formikPassword.errors.password)
										}
									/>
								}
								tooltipContent="* Parolanız en az 6 en fazla 24 karakter içermelidir."
							/>
						</div>
						<div className={gridClassNameRight}>
							<AvkInputText
								password
								fullwidth
								id="passwordRepeat"
								name="passwordRepeat"
								label="Parola Tekrar"
								hidePasswordMeter
								onBlur={formikPassword.handleBlur}
								onChange={formikPassword.handleChange}
								value={formikPassword.values.passwordRepeat}
								groupIconElement={<RepeatIcon />}
								error={
									formikPassword.touched.passwordRepeat &&
									Boolean(formikPassword.errors.passwordRepeat)
								}
							/>
						</div>
						<div className={gridClassNameButton}>
							<AvkButton
								fullwidth
								id="btnUpdatePassword"
								name="btnUpdatePassword"
								label="GÜNCELLE"
								type="button"
								className="mt-3"
								onClick={() => {
									formikPassword.touched.password = true;
									formikPassword.touched.passwordRepeat = true;
									formikPassword.validateForm().then((x) => {
										if (Object.keys(x).length === 0) {
											setShowUpdatePasswordConfirmDialog(true);
										}
									});
								}}
							/>
						</div>
					</div>
				</AvkCard>
			</div>
			{formikPassword.values.isPasswordValidationStep && (
				<AvkDialog
					title="Parola Doğrulama"
					visible={formikPassword.values.isPasswordValidationStep}
					onHide={() => setShowUpdatePasswordConfirmDialog(false)}
					className="w-11 md:w-10 lg:w-8 xl:w-4"
				>
					<form onSubmit={formikPassword.handleSubmit}>
						<div className="grid">
							<div className="col-12 mt-2 text-justify px-4">
								<span>
									Henüz parolanız güncellenmedi. Parola güncelleme
									işleminizi tamamlamak için kimliğinizi doğrulamanız
									gerekmektedir. Geçerli parolanızı aşağıdaki alana
									girerek kimliğinizi doğrulayabilir ve parola
									güncelleme işlemini tamamlayabilirsiniz.
								</span>
							</div>
							<div className="col-offset-0 lg:col-offset-2 col-12 lg:col-8">
								<AvkInputText
									password
									fullwidth
									id="currentPassword"
									name="currentPassword"
									label="Geçerli Parola"
									onBlur={formikPassword.handleBlur}
									onChange={formikPassword.handleChange}
									value={formikPassword.values.currentPassword}
									groupIconElement={<PasswordIcon fontSize="small" />}
									hidePasswordMeter
									error={
										formikPassword.touched.currentPassword &&
										Boolean(formikPassword.errors.currentPassword)
									}
								/>
							</div>
							<div className="col-offset-0 lg:col-offset-2 col-12 lg:col-8 mt-2">
								<AvkButton
									fullwidth
									id="checkButton"
									name="checkButton"
									label="DEVAM ET"
									type="submit"
								/>
							</div>
						</div>
					</form>
				</AvkDialog>
			)}

			{cropperProps.show && (
				<AvkImageCropDialog
					src={cropperProps.image ?? ""}
					onHide={() => {
						setCropperProps({ ...cropperProps, show: false });
					}}
					visible={cropperProps.show}
					OkClick={(result) => {
						getBase64FromImageUrl(result, setProfilePictureBase64);
						setProfilePicture(result);
						setCropperProps({ ...cropperProps, show: false });
					}}
				/>
			)}
		</>
	);
};

export default ProfilePage;
