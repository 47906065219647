import DarkModeIcon from "@mui/icons-material/DarkMode";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import LightModeIcon from "@mui/icons-material/LightMode";
import LogoutIcon from "@mui/icons-material/Logout";
import PasswordIcon from "@mui/icons-material/Password";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import SchoolIcon from "@mui/icons-material/School";
import SourceIcon from "@mui/icons-material/Source";
import TaskIcon from "@mui/icons-material/Task";
import { useFormik } from "formik";
import PrimeReact from "primereact/api";
import { Menubar } from "primereact/menubar";
import { MenuItem } from "primereact/menuitem";
import { ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import validationClient from "../../../api/services/validation";
import {
	addNotification,
	removeNotificationAfterLogOut,
	removeNotificationFromPage,
} from "../../../redux/slices/notification-slice";
import { SiteObject } from "../../../redux/slices/site-slice";

import { authClient, edevletClient } from "../../../api/services";
import LoginRequest from "../../../dto/request/LoginRequest";

import LoginInformationFullResponse from "../../../dto/response/LoginInformationResponse";
import { SuccessFullResponse } from "../../../dto/response/SuccessResponse";
import {
	GoogleRecaptchaObject,
	addToRecaptchaIdList,
	removeFromRecaptchaIdList,
	removeRecaptchaToken,
} from "../../../redux/slices/recaptcha-slice";
import {
	UserSliceObject,
	setOpenUserForm,
	setUserLoggedIn,
	setUserLoggedOut,
} from "../../../redux/slices/user-slice";
import { store } from "../../../redux/store";
import {
	getImageSrcUrlFromBase64String,
	isNullOrUndefinedOrEmpty,
} from "../../../utils/CommonFunctions";
import AvkButton from "../../avk-components/AvkButton";
import AvkDialog from "../../avk-components/AvkDialog";
import AvkGoogleReCaptcha from "../../avk-components/AvkGoogleReCaptcha";
import AvkInputText from "../../avk-components/AvkInputText";
import AvkNotification from "../../avk-components/AvkNotification";
import AvkSplitButton from "../../avk-components/AvkSplitButton";
import { AvkSplitButtonMenuItem } from "../../avk-components/AvkSplitButton/AvkSplitButtonObjects";
import styles from "./SemHeader.module.css";
import SemHeaderLoginValidation from "./SemHeaderValidation";

interface Theme {
	name: string;
	isDark: boolean;
	icon: ReactElement | string;
	className?: string;
	textToShow: string;
}

const themes: Theme[] = [
	{
		isDark: false,
		name: "bootstrap4-light-blue", // bootstrap4-light-blue | lara-light-green
		icon: <DarkModeIcon color="disabled" className="mr-2" />,
		textToShow: "Dark Mode",
	},
	{
		isDark: true,
		name: "bootstrap4-dark-blue", // bootstrap4-dark-blue | lara-dark-green
		icon: <LightModeIcon className="mr-2" />,
		textToShow: "Light Mode",
	},
];

type LoginFormikObject = {
	uniqueKey: string;
	password: string;
	isPasswordStep: boolean;
};

const loginInitialValue: LoginFormikObject = {
	uniqueKey: "",
	password: "",
	isPasswordStep: false,
};

const SemHeader = () => {
	const history = useHistory();

	const userValues: UserSliceObject = useSelector((props: any) => props.user);
	const siteValues: SiteObject = useSelector((props: any) => props.site);
	const recaptcha: GoogleRecaptchaObject = useSelector(
		(props: any) => props.recaptcha
	);

	const [theme, setTheme] = useState<Theme>(themes[0]);
	const [showLoginDialog, setShowLoginDialog] = useState<boolean>(false);

	useEffect(() => {
		if (
			userValues.userLoggedIn &&
			!isNullOrUndefinedOrEmpty(userValues.userInfo.email) &&
			!userValues.userInfo.emailValid
		) {
			addEmailValidationNotification();
		}

		if (userValues.userLoggedIn && !userValues.userInfo.profileInfoValid) {
			addProfileValidationNotification();
		}
	}, [userValues.userInfo]); // eslint-disable-line

	useEffect(() => {
		if (!showLoginDialog) {
			setIsPasswordStep(false);
		}
	}, [showLoginDialog]); // eslint-disable-line

	const setIsPasswordStep = (show: boolean) => {
		formik.setFieldValue("isPasswordStep", show);
		if (!show) {
			formik.setFieldValue("password", "");
		}
	};

	const addEmailValidationNotification = () => {
		const notificationId = crypto.randomUUID();
		store.dispatch(
			addNotification({
				id: notificationId,
				removeOnLogOut: true,
				position: "top-wide",
				sticky: true,
				type: "warn",
				showAlways: true,
				customContent: (
					<>
						<i
							className="pi pi-exclamation-triangle mr-5"
							style={{ fontSize: "2rem", color: "red" }}
						/>
						<span>
							E-posta adresiniz doğrulanmadı. Doğrulama işlemini
							tamamlamak için lütfen{" "}
							<AvkButton
								link
								type="button"
								className="p-0"
								label="tıklayınız"
								onClick={() => {
									validationClient.sendEmailValidation();
									store.dispatch(
										removeNotificationFromPage(notificationId)
									);
								}}
							/>
							{"."}
						</span>
					</>
				),
			})
		);
	};

	const addProfileValidationNotification = () => {
		const notificationId = crypto.randomUUID();
		store.dispatch(
			addNotification({
				id: notificationId,
				removeOnLogOut: true,
				position: "top-wide",
				sticky: true,
				type: "warn",
				showAlways: true,
				customContent: (
					<>
						<i
							className="pi pi-exclamation-triangle mr-5"
							style={{ fontSize: "2rem", color: "red" }}
						/>
						<span>
							Sistemi kullanmaya devam etmek ve tüm içeriğe erişim
							sağlamak için önce profil sayfasında yer alan zorunlu
							alanları doldurmanız gerekmektedir.
						</span>
					</>
				),
			})
		);
	};

	useEffect(() => {
		if (userValues.openLoginForm) {
			setShowLoginDialog(true);
			store.dispatch(setOpenUserForm(false));
		}
	}, [userValues.openLoginForm]);

	const formik = useFormik({
		initialValues: loginInitialValue,
		validationSchema: SemHeaderLoginValidation,
		validateOnBlur: true,
		validateOnChange: true,
		onSubmit: () => {
			if (formik.values.isPasswordStep) {
				if (!isNullOrUndefinedOrEmpty(recaptcha.token)) {
					authClient
						.login({
							uniqueKey: formik.values.uniqueKey,
							password: formik.values.password,
							recaptchaToken: recaptcha.token,
						} as LoginRequest)
						.then(({ data }: LoginInformationFullResponse) => {
							store.dispatch(
								setUserLoggedIn({
									name: data.name,
									surname: data.surname,
									email: data.email,
									emailValid: data.emailValid,
									profileInfoValid: data.profileInfoValid,
								})
							);
							store.dispatch(
								addNotification({
									id: crypto.randomUUID(),
									title: "Giriş Başarılı",
									message: "Giriş işlemi başarı ile tamamlandı.",
									type: "success",
									showTime: 1000,
								})
							);
							formik.setValues(loginInitialValue);
							history.push("/");
							setShowLoginDialog(false);
						})
						.finally(() => {
							store.dispatch(removeRecaptchaToken());
						});
				}
			} else {
				setIsPasswordStep(true);
				store.dispatch(removeRecaptchaToken());
			}
		},
	});

	useEffect(() => {
		if (!isNullOrUndefinedOrEmpty(recaptcha.token)) {
			formik.submitForm();
		}
	}, [recaptcha.token]); // eslint-disable-line

	const changeMyTheme = () => {
		var newTheme = themes.find((x) => x.name !== theme.name) as Theme;
		PrimeReact?.changeTheme?.(theme.name, newTheme?.name, "app-theme", () => {
			setTheme(newTheme);
		});
	};

	const getConcattedUserNameAndSurname = (): string => {
		return userValues.userInfo.name + " " + userValues.userInfo.surname;
	};

	const splitButtonMMenuitems: AvkSplitButtonMenuItem[] = [
		{
			label: theme.textToShow,
			icon: theme.icon,
			onClick: () => {
				changeMyTheme();
			},
		},
	];

	const modelItems: MenuItem[] = [
		{
			label: "Eğitimler",
			icon: <SchoolIcon className="mr-2" />,
			className: "mb-1",
			command: () => {
				history.push("/");
			},
		},
		{
			label: "Sertifika Doğrula",
			icon: <TaskIcon className="mr-2" />,
			className: "mb-1",
			command: () => {
				history.push("/certificate/verify");
			},
		},
	];

	const menuStart = (
		<button
			type="button"
			className={"mr-2 md:mr-5 pb-2 " + styles.tButton}
			onClick={() => {
				history.push("/");
			}}
		>
			<div className="flex align-items-center gap-3">
				<img
					alt="company_icon"
					src={getImageSrcUrlFromBase64String(
						siteValues.logoBase64,
						"image/jpeg"
					)}
					style={{ height: "28px" }}
				/>
				<span className=" mt-1 font-bold text-xl hidden md:block">
					{siteValues.title}
				</span>
			</div>
		</button>
	);

	const menuEnd = (
		<div className="flex align-items-center gap-3 md:gap-5">
			<AvkSplitButton
				label={
					!userValues.userLoggedIn
						? "Giriş Yap / Kayıt Ol"
						: getConcattedUserNameAndSurname()
				}
				icon={<PersonOutlineIcon className="mr-2" />}
				onClick={() => {
					if (userValues.userLoggedIn) {
						history.push("/profile");
					} else {
						setShowLoginDialog(true);
					}
				}}
				menuItems={splitButtonMMenuitems}
			/>
		</div>
	);

	if (userValues.userLoggedIn) {
		splitButtonMMenuitems.push({
			label: "Çıkış Yap",
			icon: <LogoutIcon className="mr-2" />,
			onClick: () => {
				authClient.logout().then(() => {
					store.dispatch(removeNotificationAfterLogOut());
					store.dispatch(setUserLoggedOut());
					store.dispatch(
						addNotification({
							id: crypto.randomUUID(),
							title: "Çıkış Başarılı",
							message: "Çıkış işlemi başarı ile tamamlandı.",
							type: "warn",
							showTime: 1000,
						})
					);
					history.push("/");
				});
			},
		});

		modelItems.push({
			label: "Sertifikalarım",
			icon: <SourceIcon className="mr-2" />,
			className: "mb-1",
			command: () => {
				history.push("/my-certificates");
			},
		});
	}

	useEffect(() => {
		if (formik.values.isPasswordStep) {
			store.dispatch(addToRecaptchaIdList("loginButton"));
		} else {
			store.dispatch(removeFromRecaptchaIdList("loginButton"));
		}
		return () => {
			store.dispatch(removeFromRecaptchaIdList("loginButton"));
		};
	}, [formik.values.isPasswordStep]); // eslint-disable-line

	return (
		<>
			<AvkNotification />
			{recaptcha.elementIdList.length > 0 && <AvkGoogleReCaptcha />}
			<Menubar start={menuStart} model={modelItems} end={menuEnd} />
			{showLoginDialog && (
				<AvkDialog
					title="Kullanıcı Girişi"
					visible={showLoginDialog}
					onHide={() => setShowLoginDialog(false)}
					className="w-11 md:w-10 lg:w-8 xl:w-4"
				>
					<form onSubmit={formik.handleSubmit}>
						<div className="grid">
							<div className="col-6">
								<AvkButton
									fullwidth
									id="edevletRegisterButton"
									name="edevletRegisterButton"
									label="E-DEVLET İLE KAYIT OL"
									className="mb-3 font-bold"
									style={{
										backgroundColor: "#CE0005",
										color: "white",
										height: "70px",
									}}
									icon={
										<img
											src="/images/edevlet_logo_white.png"
											alt="e-devlet"
											style={{ height: "40px" }}
										/>
									}
									onClick={() => {
										edevletClient.assignLogin();
									}}
									type="button"
								/>
							</div>
							<div className="col-6">
								<AvkButton
									fullwidth
									id="edevletLoginButton"
									name="edevletLoginButton"
									label="E-DEVLET İLE GİRİŞ YAP"
									className="mb-3 font-bold"
									style={{
										backgroundColor: "#CE0005",
										color: "white",
										height: "70px",
									}}
									icon={
										<img
											src="/images/edevlet_logo_white.png"
											alt="e-devlet"
											style={{ height: "40px" }}
										/>
									}
									onClick={() => {
										edevletClient.assignLogin();
									}}
									type="button"
								/>
							</div>
							<div className="col-5">
								<hr />
							</div>
							<div className="col-2 text-center">YA DA</div>
							<div className="col-5">
								<hr />
							</div>
						</div>
						<div className="grid">
							<div
								className={
									formik.values.isPasswordStep
										? " col-12 md:col-6 "
										: " col-12"
								}
							>
								<AvkInputText
									fullwidth
									id="uniqueKey"
									name="uniqueKey"
									label="TC Kimlik No / Pasaport No"
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									value={formik.values.uniqueKey}
									groupIconElement={<FingerprintIcon />}
								/>
							</div>
							<div
								className={
									formik.values.isPasswordStep
										? "col-12 md:col-6"
										: " hidden"
								}
							>
								<AvkInputText
									tabIndex={formik.values.isPasswordStep ? 0 : -1}
									password
									hidePasswordMeter
									fullwidth
									id="password"
									name="password"
									label="Parola"
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									value={formik.values.password}
									groupIconElement={<PasswordIcon />}
								/>
							</div>
							<div className="col-offset-6 col-6 xl:col-offset-8 xl:col-4 my-0 py-0 text-center">
								<AvkButton
									link
									id="signUpButton"
									name="signUpButton"
									label="Parolamı unuttum"
									className="my-0 py-0"
									type="button"
									onClick={() => {
										if (
											isNullOrUndefinedOrEmpty(
												formik.values.uniqueKey
											)
										) {
											store.dispatch(
												addNotification({
													id: crypto.randomUUID(),
													title: "Başarısız",
													message:
														"Lütfen TC Kimlik numaranızı ya da pasaport numaranızı giriniz.",
													type: "error",
												})
											);
											return;
										} else {
											store.dispatch(
												addNotification({
													id: crypto.randomUUID(),
													title: "Lütfen Bekleyiniz",
													message:
														"Parola sıfırlama işleminiz için gerekli kontrolleri yapıyoruz.",
													type: "info",
												})
											);
										}
										authClient
											.resetPasswordEmail(formik.values.uniqueKey)
											.then(({ data }: SuccessFullResponse) => {
												store.dispatch(
													addNotification({
														id: crypto.randomUUID(),
														title: "Başarılı",
														message: data.message,
														type: "success",
													})
												);
											});
									}}
								/>
							</div>
							<div className="col-6 xl:col-8">
								<AvkButton
									link
									id="signUpButton"
									name="signUpButton"
									label="Hesap oluştur"
									type="button"
									onClick={() => {
										history.push(
											"/register/" + formik.values.uniqueKey
										);
										setShowLoginDialog(false);
										formik.setFieldValue("uniqueKey", "");
									}}
								/>
							</div>
							<div className="col-6 xl:col-4">
								<AvkButton
									fullwidth
									id="loginButton"
									name="loginButton"
									label="DEVAM ET"
									type="submit"
								/>
							</div>
						</div>
					</form>
				</AvkDialog>
			)}
		</>
	);
};

export default SemHeader;
