import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AvkCard from "../../../components/avk-components/AvkCard";
import {
	checkIsShowRegisterResultFromLocalStorage,
	removeShowRegisterResultFromLocalStorage,
} from "../../../utils/AuthFunctions";

const RegisterResultPage = () => {
	const history = useHistory();

	const [showResult, setShowResult] = useState<boolean>(false);

	useEffect(() => {
		if (checkIsShowRegisterResultFromLocalStorage()) {
			setShowResult(true);
			removeShowRegisterResultFromLocalStorage();
		} else {
			history.push("/");
		}
	}, []); // eslint-disable-line

	return (
		<>
			{showResult && (
				<div className="grid w-full h-full">
					<div className="col-8 col-offset-2 mt-8">
						<AvkCard className="w-full">
							<div className="grid w-full h-full">
								{true ? (
									<div className="col-12">
										<div className="col-12 text-2xl font-bold text-center">
											KAYIT BAŞARI İLE TAMAMLANDI
										</div>
										<div className="col-8 col-offset-2 text-lg text-justify">
											Kayıt işleminiz başarı ile tamamlandı.
											Kullanıcı giriş ekranı üzerinden sisteme giriş
											yapabilirsiniz. E-posta adresinizi
											doğrulayabileceğiniz doğrulama linki e-posta
											adresinize gönderilmiştir. Lütfen gönderilen
											e-posta içerisinde yer alan doğrulama linkine
											tıklayarak e-posta adresinizi doğrulayınız.
											Doğrulama için gerekli e-posta tarafınıza
											ulaşmadıysa giriş yaparak tekrar gönderilmesini
											talep edebilirsiniz.
										</div>
									</div>
								) : (
									<span>Opss...</span>
								)}
							</div>
						</AvkCard>
					</div>
				</div>
			)}
		</>
	);
};

export default RegisterResultPage;
