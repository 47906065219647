import * as yup from "yup";
import {
	checkTckn,
	isNullOrUndefinedOrEmpty,
} from "../../utils/CommonFunctions";

const tcknOrPassportErrorMessage =
	"TC Kimlik No ya da Pasaport No alanlarından birini doldurunuz.";

const RegisterValidation = yup.object({
	governmentId: yup
		.string()
		.test(
			"tcknOrPassportNumberRequired",
			tcknOrPassportErrorMessage,
			(value, context) => {
				const isTcknEmpty = isNullOrUndefinedOrEmpty(value as string);
				if (
					(!isTcknEmpty && !checkTckn(value as string)) ||
					(isTcknEmpty &&
						isNullOrUndefinedOrEmpty(context.parent.pasaportId))
				) {
					return false;
				}
				return true;
			}
		),
	pasaportId: yup
		.string()
		.test(
			"passportNumberOrTcknRequired",
			tcknOrPassportErrorMessage,
			(value, context) => {
				if (
					isNullOrUndefinedOrEmpty(value as string) &&
					isNullOrUndefinedOrEmpty(context.parent.governmentId)
				) {
					return false;
				}
				return true;
			}
		),
	email: yup
		.string()
		.email()
		.required("Lütfen e-posta adresinizi doldurunuz."),
	name: yup.string().required("Lütfen ad bilginizi doldurunuz."),
	surname: yup.string().required("Lütfen soyad bilginizi doldurunuz."),
	password: yup.string().required("Lütfen bir parola belirleyiniz."),
	passwordRepeat: yup
		.string()
		.required()
		.test(
			"passwordsShouldMatch",
			"Parolalar eşleşmiyor. Lütfen parolanızı kontrol ediniz.",
			(value, context) => {
				if (isNullOrUndefinedOrEmpty(value)) {
					return false;
				}
				return value === context.parent.password;
			}
		),
});

export default RegisterValidation;
