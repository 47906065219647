import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";
import AvkPrivateRoute from "./components/avk-components/AvkPrivateRoute";
import Layout from "./hoc/Layout";
import AnnouncementPage from "./pages/announcement";
import {
	CertificateShow,
	CertificateVerifyPage,
	OwnedCertificatesPage,
} from "./pages/certificate";
import MainPage from "./pages/main";
import ResetPasswordPage from "./pages/password-reset";
import ProfilePage from "./pages/profile";
import { CertificationDetailPage } from "./pages/program";
import RedirectPage from "./pages/redirect";
import RegisterPage from "./pages/register";
import RegisterResultPage from "./pages/register/result";
import ValidationResultPage from "./pages/validation/email";
import { clearRedirectTo } from "./redux/slices/auth-slice";
import { store } from "./redux/store";

function App() {
	const history = useHistory();

	const redirectTo: string = useSelector(
		(props: any) => props.auth.redirectTo
	);

	useEffect(() => {
		if (redirectTo !== "") {
			store.dispatch(clearRedirectTo());
			history.push(redirectTo);
		}
	}, [redirectTo]); // eslint-disable-line

	let routes = (
		<Switch>
			<AvkPrivateRoute public exact path="/">
				<MainPage />
			</AvkPrivateRoute>
			<Route exact path="/certificate/verify">
				<CertificateVerifyPage />
			</Route>
			<Route exact path="/certificate/verified">
				<CertificateShow />
			</Route>
			<Route path="/register/:uniqueKey?">
				<RegisterPage />
			</Route>
			<Route path="/register-result">
				<RegisterResultPage />
			</Route>
			<Route path="/email-validation-result/:result">
				<ValidationResultPage />
			</Route>
			<Route path="/reset-password/:validationKey/:resetPasswordKey">
				<ResetPasswordPage />
			</Route>
			<Route path="/announcement/:id">
				<AnnouncementPage />
			</Route>
			<Route path="/certification-detail/:id">
				<CertificationDetailPage />
			</Route>
			<AvkPrivateRoute exact path="/profile">
				<ProfilePage />
			</AvkPrivateRoute>
			<AvkPrivateRoute exact path="/my-certificates">
				<OwnedCertificatesPage />
			</AvkPrivateRoute>
			<AvkPrivateRoute path="*">
				<RedirectPage redirectUrl="/" />
			</AvkPrivateRoute>
		</Switch>
	);

	return <Layout>{routes}</Layout>;
}

export default App;
