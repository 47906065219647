import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import moment from "moment";
import { useEffect, useState } from "react";
import { certificateClient } from "../../../api/services";
import AvkCard from "../../../components/avk-components/AvkCard";
import AvkDataListView from "../../../components/avk-components/AvkDataListView";
import AvkScrollPanel from "../../../components/avk-components/AvkScrollPanel";
import { UserCertificateResponse } from "../../../dto/response/UserCertificatesResponse";

const OwnedCertificatesPage = () => {
	const [certificates, setCertificates] =
		useState<UserCertificateResponse[]>();

	useEffect(() => {
		certificateClient.getUserCertificates().then(({ data }) => {
			setCertificates(data.userCertificates);
		});
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="flex align-items-center justify-content-center font-bold w-full h-full">
			<AvkCard className="w-full">
				<div className="flex align-items-center justify-content-center flex-column font-bold mx-2 mb-2 w-full h-full">
					<span className="text-5xl font-bold mb-3">Sertifikalarım</span>
					<AvkScrollPanel height="70vh">
						<AvkDataListView
							data={
								certificates?.map(
									(certificate: UserCertificateResponse) => {
										return {
											id: certificate.id,
											title:
												certificate.name +
												" (" +
												certificate.programCode +
												")",
											content: (
												<span className="font-bold">
													Sertifika Tarihi:{" "}
													{moment(certificate.date).format(
														"DD.MM.YYYY"
													)}
												</span>
											),
										};
									}
								) ?? []
							}
							events={(id: number) => (
								<PictureAsPdfIcon
									className="cursor-pointer"
									color="error"
									style={{ marginTop: "-5px" }}
									onClick={() => {
										const programCode =
											certificates?.find((x) => x.id === id)
												?.programCode ?? "";
										certificateClient
											.getCertificateByProgram(programCode)
											.then(({ data }) => {
												const blob = new Blob([data], {
													type: "application/pdf",
												});
												window.open(URL.createObjectURL(blob));
											});
									}}
								/>
							)}
						/>
					</AvkScrollPanel>
				</div>
			</AvkCard>
		</div>
	);
};

export default OwnedCertificatesPage;
