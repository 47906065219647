import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
	GoogleRecaptchaObject,
	setRecaptchaToken,
} from "../../../redux/slices/recaptcha-slice";
import { store } from "../../../redux/store";
import {
	isNullOrUndefined,
	isNullOrUndefinedOrEmpty,
} from "../../../utils/CommonFunctions";

const AvkGoogleReCaptcha = () => {
	const recaptchaInfo: GoogleRecaptchaObject = useSelector(
		(props: any) => props.recaptcha
	);

	const handleLoaded = async (e: any) => {
		e.preventDefault();
		if (!isNullOrUndefinedOrEmpty(recaptchaInfo.siteKey)) {
			await (window as any).grecaptcha
				.execute(recaptchaInfo.siteKey, { action: "homepage" })
				.then((token: string) => {
					store.dispatch(setRecaptchaToken(token));
				});
		}
	};

	useEffect(() => {
		for (let itemId of recaptchaInfo.elementIdList) {
			const submitBtn = document.getElementById(itemId);
			if (
				!isNullOrUndefined(submitBtn) &&
				!isNullOrUndefinedOrEmpty(recaptchaInfo.siteKey)
			) {
				submitBtn?.removeEventListener("click", handleLoaded);
				submitBtn?.addEventListener("click", handleLoaded);
			}
		}
	}, [recaptchaInfo.elementIdList]); // eslint-disable-line

	useEffect(() => {
		let script = document.createElement("script");
		if (!isNullOrUndefinedOrEmpty(recaptchaInfo.siteKey)) {
			script.src =
				"https://www.google.com/recaptcha/api.js?render=" +
				recaptchaInfo.siteKey;
			document.body.appendChild(script);
			return () => {
				document.body.removeChild(script);
			};
		}
	}, [recaptchaInfo.siteKey]); // eslint-disable-line

	useEffect(() => {
		return () => {
			const nodeBadge = document.querySelector(".grecaptcha-badge");
			if (nodeBadge?.parentNode != null) {
				document.body.removeChild(nodeBadge.parentNode);
			}
		};
	}, []);

	return (
		<div
			className={"g-recaptcha"}
			data-sitekey={recaptchaInfo.siteKey}
			data-size={"invisible"}
		></div>
	);
};

export default AvkGoogleReCaptcha;
