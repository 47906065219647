import { Card } from "primereact/card";
import React from "react";
import "./AvkCard.css";
interface AvkCardProps {
	children?: React.ReactNode;
	shadow?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
	onClick?: () => void;
	header?: React.ReactNode;
	footer?: React.ReactNode;
	title?: React.ReactNode;
	className?: string;
	style?: React.CSSProperties;
}

const AvkCard = (props: AvkCardProps) => {
	let shadow = " shadow-" + (props.shadow ?? 4);
	shadow = shadow === "shadow-0" ? "shadow-none" : shadow;
	return (
		<Card className={`${props.className} ${shadow} `} style={props.style}>
			<div onClick={props.onClick} className="w-full">
				{props.header}
				<div className="px-3 pt-0">
					<div className="py-0 m-0 h-auto">{props.title}</div>
					{props.children}
				</div>
			</div>
			<div className="pb-3 px-3">{props.footer}</div>
		</Card>
	);
};

export default AvkCard;
