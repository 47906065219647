import moment from "moment";
import { ProgressSpinner } from "primereact/progressspinner";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { programClient } from "../../../api/services";
import AvkButton from "../../../components/avk-components/AvkButton";
import AvkCard from "../../../components/avk-components/AvkCard";
import { StatusTypes } from "../../../constants/registerInfo";
import {
	ParticipantProgramRegisteredInfo,
	ProgramDetailDTO,
} from "../../../dto/response/ProgramDTO";
import { addNotification } from "../../../redux/slices/notification-slice";
import {
	UserSliceObject,
	setOpenUserForm,
} from "../../../redux/slices/user-slice";
import { store } from "../../../redux/store";
import "./CertificationDetailPage.css";
import DetailPageContainer from "./DetailPageContainer";

const CertificationDetailPage = () => {
	const userValues: UserSliceObject = useSelector((props: any) => props.user);
	const courseApplyActive: boolean = useSelector(
		(props: any) => props.site.courseApplyActive
	);
	const params: { id: string; type: string } = useParams();

	const [programDetailData, setProgramDetailData] =
		useState<ProgramDetailDTO>();
	const [loading, setLoading] = useState<boolean>(true);
	const [registerInfo, setRegisterInfo] = useState<
		ParticipantProgramRegisteredInfo | undefined
	>();
	useEffect(() => {
		programClient
			.getProgramDetails(parseInt(params.id))
			.then((res) => {
				setProgramDetailData(res.data);
				if (userValues.userLoggedIn) {
					programClient
						.getParticipantProgramRegisterInfo(res.data.id)
						.then((res) => {
							setRegisterInfo(res.data);
						})
						.catch((err) => {
							setRegisterInfo(undefined);
						});
				}
			})
			.finally(() => {
				setLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params.id]);
	if (loading) return <ProgressSpinner />;

	return programDetailData ? (
		<div className=" flex flex-row flex-wrap align-items-start justify-content-around w-full h-full mt-8">
			<AvkCard className="w-10 md:w-12 xl:w-8 lg:w-8" shadow={5}>
				<div className="flex align-items-center justify-content-center flex-column px-2 mb-2 w-full h-full">
					<DetailPageContainer label="Eğitim Adı" content>
						{programDetailData.courseName}
					</DetailPageContainer>
					<DetailPageContainer
						label="Eğitim İçeriği"
						content
						className="text-justify"
					>
						{programDetailData.explanation}
					</DetailPageContainer>
					<DetailPageContainer label="Eğitmenler" content>
						{programDetailData.instructors?.map((item) => (
							<span>{`${item.title} ${item.name}`}</span>
						))}
					</DetailPageContainer>
				</div>
			</AvkCard>
			<div
				className="w-10 md:w-12 xl:w-3 lg:w-3  p-0 h-full flex flex-column align-item-center justify-content-start"
				style={{ marginTop: 16 }}
			>
				<AvkCard className="w-full" shadow={5}>
					<div className="flex align-items-start justify-content-start flex-column px-2 mb-2 w-full h-full">
						<div className="font-bold text-xl w-full text-center">
							Eğitim Bilgileri
						</div>
						<DetailPageContainer label="Eğitim Türü">
							{programDetailData.courseType}
						</DetailPageContainer>
						<DetailPageContainer label="Durum">
							<span>
								{programDetailData.active
									? "Devam Ediyor/Kayıt Alınıyor"
									: "Tamamlandı"}
							</span>
						</DetailPageContainer>
						<DetailPageContainer label="Ücret">
							{programDetailData.price}
						</DetailPageContainer>
						<DetailPageContainer label="Ders Saati">
							{programDetailData.totalHour}
						</DetailPageContainer>
						<DetailPageContainer label="Başlangıç">
							<span>
								{moment(programDetailData.startDate).format(
									"DD-MM-YYYY"
								)}
							</span>
						</DetailPageContainer>
						<DetailPageContainer label="Bitiş">
							<span>
								{moment(programDetailData.endData).format("DD-MM-YYYY")}
							</span>
						</DetailPageContainer>
					</div>
				</AvkCard>
				{courseApplyActive && programDetailData.active && (
					<AvkCard className="w-full mt-5" shadow={5}>
						<div className="flex align-items-center justify-content-center flex-column px-2 mb-2 w-full h-full">
							<div className="font-bold">Başvuru</div>
							<div
								className="mt-3 w-full flex flex-row
										justify-content-center align-items-center w-full"
							>
								{userValues.userLoggedIn ? (
									registerInfo?.registered &&
									registerInfo.status !== StatusTypes.DECLINED ? (
										registerInfo.status === StatusTypes.PENDING ? (
											"Başvuru Yapıldı"
										) : (
											"Programa Kayıtlısın"
										)
									) : (
										<div>
											<div className="text-orange-600 p-2">
												{registerInfo?.status ===
													StatusTypes.DECLINED &&
													"Daha önceki başvurunuz kabul edilmedi."}
											</div>
											<AvkButton
												size="large"
												fullwidth
												onClick={() => {
													programClient
														.registerProgram(programDetailData.id)
														.then((res) => {
															setRegisterInfo({
																status: StatusTypes.PENDING,
																registered: true,
															});
															store.dispatch(
																addNotification({
																	id: crypto.randomUUID(),
																	title: "Başarılı",
																	message: res.data.message,
																	type: "success",
																})
															);
														});
												}}
												label={
													registerInfo?.status ===
													StatusTypes.DECLINED
														? "TEKRAR BAŞVUR"
														: "BAŞVUR"
												}
											/>
										</div>
									)
								) : (
									<AvkButton
										onClick={() => {
											store.dispatch(setOpenUserForm(true));
										}}
										className="text-xl flex align-items-center justify-content-center"
										label="Kayıt Ol veya Giriş Yap"
									/>
								)}
							</div>
						</div>
					</AvkCard>
				)}
			</div>
		</div>
	) : (
		<h3>Eğitim Bilgileri Bulunamadı</h3>
	);
};

export default CertificationDetailPage;
