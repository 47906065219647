import CreateAccountRequest from "../../../dto/request/CreateAccountRequest";
import UpdateAccountRequest from "../../../dto/request/UpdateAccountRequest";
import UpdatePasswordRequest from "../../../dto/request/UpdatePasswordRequest";
import { UpdateAccountResponse } from "../../../dto/response/UpdateAccountResponse";
import { ApiClient } from "../../ApiConfig";

let client = ApiClient.getInstance();

const participantClient = {
	createAccount(request: CreateAccountRequest) {
		return client.post("/participants", request);
	},
	updateAccount(request: UpdateAccountRequest) {
		return client.put<UpdateAccountResponse>("/participants", request);
	},
	updatePassword(request: UpdatePasswordRequest) {
		return client.put("/participants/update-password", request);
	},
	getAccountInformation() {
		return client.get("/participants");
	},
};
export default participantClient;
