import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type GoogleRecaptchaObject = {
	elementIdList: string[];
	siteKey: string;
	token: string;
};

export const recaptchaSlice = createSlice({
	name: "recaptcha",
	initialState: {
		elementIdList: [],
		siteKey: "",
		token: "",
	} as GoogleRecaptchaObject,
	reducers: {
		setSiteKey: (state, action: PayloadAction<string>) => {
			state.siteKey = action.payload;
		},
		addToRecaptchaIdList: (state, action: PayloadAction<string>) => {
			state.elementIdList = [...state.elementIdList, action.payload];
		},
		removeFromRecaptchaIdList: (state, action: PayloadAction<string>) => {
			state.elementIdList = state.elementIdList?.filter(
				(x) => x !== action.payload
			);
		},
		clearRecaptchaElementIdList: (state) => {
			state.elementIdList = [];
		},
		setRecaptchaToken: (state, action: PayloadAction<string>) => {
			state.token = action.payload;
		},
		removeRecaptchaToken: (state) => {
			state.token = "";
		},
	},
});

export const {
	setSiteKey,
	addToRecaptchaIdList,
	removeFromRecaptchaIdList,
	clearRecaptchaElementIdList,
	setRecaptchaToken,
	removeRecaptchaToken,
} = recaptchaSlice.actions;
