import { DataView } from "primereact/dataview";
import AvkDataListViewTemplate from "../AvkDataListViewTemplate";
import { AvkDataListViewData } from "./AvkDataListObjects";

interface AvkDataListViewProps {
	data: AvkDataListViewData[];
	events?: (id: number, title?: string) => React.ReactNode;
}

const AvkDataGridView = (props: AvkDataListViewProps) => {
	return (
		<DataView
			layout="list"
			value={props.data}
			itemTemplate={(data: AvkDataListViewData) => (
				<AvkDataListViewTemplate
					data={{
						id: data.id,
						title: data.title,
						content: data.content,
						onClick: (id: number) => {
							data.onClick?.(id);
						},
					}}
					events={props.events}
				/>
			)}
		/>
	);
};

export default AvkDataGridView;
