import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { authSlice } from "./slices/auth-slice";
import { notificationSlice } from "./slices/notification-slice";
import { recaptchaSlice } from "./slices/recaptcha-slice";
import { siteSlice } from "./slices/site-slice";
import { userSlice } from "./slices/user-slice";

export const store = configureStore({
	reducer: combineReducers({
		auth: authSlice.reducer,
		site: siteSlice.reducer,
		user: userSlice.reducer,
		recaptcha: recaptchaSlice.reducer,
		notification: notificationSlice.reducer,
	}),
	devTools: process.env.NODE_ENV !== "production",
});
