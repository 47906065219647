import { setAsShowEmailValidationResultToLocalStorage } from "../../../utils/AuthFunctions";
import { ApiClient } from "../../ApiConfig";

let client = ApiClient.getInstance();

const validationClient = {
	sendEmailValidation() {
		setAsShowEmailValidationResultToLocalStorage();
		return client.get("/validation/email");
	},
};

export default validationClient;
