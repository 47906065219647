import PasswordIcon from "@mui/icons-material/Password";
import RepeatIcon from "@mui/icons-material/Repeat";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { authClient } from "../../api/services";
import AvkButton from "../../components/avk-components/AvkButton";
import AvkCard from "../../components/avk-components/AvkCard";
import AvkInputText from "../../components/avk-components/AvkInputText";
import AvkTooltip from "../../components/avk-components/AvkTooltip";
import ResetPasswordRequest from "../../dto/request/ResetPasswordRequest";
import { addNotification } from "../../redux/slices/notification-slice";
import {
	addToRecaptchaIdList,
	removeFromRecaptchaIdList,
} from "../../redux/slices/recaptcha-slice";
import { store } from "../../redux/store";
import { isNullOrUndefinedOrEmpty } from "../../utils/CommonFunctions";
import { ResetPasswordValidation } from "./ResetPasswordValidation";

type UserChangePasswordObject = {
	password: string;
	passwordRepeat: string;
};

const ResetPasswordPage = () => {
	const history = useHistory();
	const { validationKey, resetPasswordKey }: any = useParams();
	const recaptchaToken: string = useSelector(
		(props: any) => props.recaptcha.token
	);

	const recaptchaButtonId = "btnUpdatePassword";
	useEffect(() => {
		authClient
			.checkResetPassword(validationKey, resetPasswordKey)
			.then(() => {
				store.dispatch(addToRecaptchaIdList(recaptchaButtonId));
			})
			.catch(() => {
				history.push("/");
			});
		return () => {
			store.dispatch(removeFromRecaptchaIdList(recaptchaButtonId));
		};
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const formikPassword = useFormik({
		initialValues: {
			password: "",
			passwordRepeat: "",
		} as UserChangePasswordObject,
		validationSchema: ResetPasswordValidation,
		validateOnBlur: true,
		validateOnChange: true,
		onSubmit: () => {
			if (!isNullOrUndefinedOrEmpty(recaptchaToken)) {
				authClient
					.resetPassword({
						validationKey: validationKey,
						resetPasswordCode: resetPasswordKey,
						newPassword: formikPassword.values.password,
						recaptchaToken: recaptchaToken,
					} as ResetPasswordRequest)
					.then(() => {
						store.dispatch(
							addNotification({
								id: crypto.randomUUID(),
								title: "Başarılı",
								message:
									"Parola sıfırlama işleminiz başarı ile tamamlandı. Yeni parolanız ile sisteme giriş yapabilirsiniz.",
								type: "success",
							})
						);
						history.push("/");
					});
			}
		},
	});

	useEffect(() => {
		if (!isNullOrUndefinedOrEmpty(recaptchaToken)) {
			formikPassword.submitForm();
		}
	}, [recaptchaToken]); // eslint-disable-line

	const gridClassNameLeft = "col-offset-0 col-12 lg:col-offset-1 lg:col-5";
	const gridClassNameRight = "col-offset-0 col-12 lg:col-5";
	const gridClassNameButton =
		"text-center col-offset-0 col-12 lg:col-offset-8 lg:col-3";
	return (
		<div className="flex align-items-center justify-content-center flex-column h-full w-full">
			<AvkCard>
				<form onSubmit={formikPassword.handleSubmit}>
					<div className="grid mt-4">
						<div className="col-12 text-center">
							<span className="font-bold text-3xl md:text-5xl">
								Parola Sıfırla
							</span>
						</div>
						<div className={gridClassNameLeft}>
							{/* 
												FIXME: Update password rules !.. 
												Create a component for profile and register page
										*/}
							<AvkTooltip
								showOnFocus
								matchingClassName="password-tooltip"
								content={
									<AvkInputText
										password
										fullwidth
										id="password"
										name="password"
										label="Yeni Parola"
										className="password-tooltip"
										onBlur={formikPassword.handleBlur}
										onChange={formikPassword.handleChange}
										value={formikPassword.values.password}
										groupIconElement={<PasswordIcon />}
										error={
											formikPassword.touched.password &&
											Boolean(formikPassword.errors.password)
										}
									/>
								}
								tooltipContent="* Parolanız en az 6 en fazla 24 karakter içermelidir."
							/>
						</div>
						<div className={gridClassNameRight}>
							<AvkInputText
								password
								fullwidth
								id="passwordRepeat"
								name="passwordRepeat"
								label="Yeni Parola Tekrar"
								hidePasswordMeter
								onBlur={formikPassword.handleBlur}
								onChange={formikPassword.handleChange}
								value={formikPassword.values.passwordRepeat}
								groupIconElement={<RepeatIcon />}
								error={
									formikPassword.touched.passwordRepeat &&
									Boolean(formikPassword.errors.passwordRepeat)
								}
							/>
						</div>
						<div className={gridClassNameButton}>
							<AvkButton
								fullwidth
								id="btnUpdatePassword"
								name="btnUpdatePassword"
								label="SIFIRLA"
								type="submit"
								className="mt-3"
							/>
						</div>
					</div>
				</form>
			</AvkCard>
		</div>
	);
};

export default ResetPasswordPage;
