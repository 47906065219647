import { Calendar } from "primereact/calendar";

interface AvkCalenderProps {
	onChange: (e: any) => void;
	onBlur?: (e: any) => void;
	label: string;
	id: string;
	name?: string;
	value?: Date;
	className?: string;
	inputClassName?: string;
	fullwidth?: boolean;
	error?: boolean;
	errorHelperText?: string;
	dateFormat?: string;
	showIcon?: boolean;
	groupIconElement?: JSX.Element | string;
}

const AvkCalender = (props: AvkCalenderProps) => {
	const fullwidthClassName = props.fullwidth ? "w-full " : "";
	const inputClassName = props.inputClassName ?? "";
	let className = props.className ?? "";
	className += props.error ? " p-invalid" : "";
	className += props.error && props.errorHelperText ? " mb-2" : "";

	const baseComponent = (
		<div>
			<span className={"p-float-label " + fullwidthClassName + className}>
				<Calendar
					inputId={props.id}
					name={props.name}
					className={"p-inputtext-lg " + fullwidthClassName + className}
					inputClassName={fullwidthClassName + inputClassName}
					onBlur={props.onBlur}
					onChange={props.onChange}
					value={props.value}
					dateFormat={props.dateFormat}
					showIcon={props.showIcon}
				/>
				<label htmlFor={props.id}>{props.label}</label>
			</span>
			{props.error && (
				<span
					className="text-red-600"
					style={{ position: "absolute", left: 0, bottom: -15 }}
				>
					{props.errorHelperText ?? ""}
				</span>
			)}
		</div>
	);

	return (
		<>
			{props.groupIconElement ? (
				<div
					className={
						"p-inputgroup flex-1 mt-5 " + fullwidthClassName + className
					}
				>
					<span className="p-inputgroup-addon w-1">
						{props.groupIconElement}
					</span>
					<div className="flex-grow-1">{baseComponent}</div>
				</div>
			) : (
				<div className="mt-5">{baseComponent}</div>
			)}
		</>
	);
};

export default AvkCalender;
