import { Dialog } from "primereact/dialog";
import React, { ReactElement } from "react";

interface AvkDialogProps {
	onHide: () => void;
	visible: boolean;
	children: ReactElement;
	title?: string;
	className?: string;
	position?:
		| "center"
		| "top"
		| "bottom"
		| "left"
		| "right"
		| "top-left"
		| "top-right"
		| "bottom-left"
		| "bottom-right"
		| undefined;
	footer?: React.ReactElement;
	maximizable?: boolean;
	maximized?: boolean;
}

const AvkDialog = (props: AvkDialogProps) => {
	return (
		<Dialog
			visible={props.visible}
			header={props.title}
			className={props.className}
			position={props.position}
			maximizable={props.maximizable}
			maximized={props.maximized}
			modal
			footer={props.footer}
			onHide={props.onHide}
		>
			{props.children}
		</Dialog>
	);
};

export default AvkDialog;
