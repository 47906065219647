import { ReactElement } from "react";

interface AvkSemBodyProps {
	children?: ReactElement;
	childrenLeftPanel?: ReactElement;
	childrenRightPanel?: ReactElement;
}

export const SemBody = (props: AvkSemBodyProps) => {
	return (
		<div className="flex flex-column md:flex-row w-full ">
			<div className="flex align-items-start justify-content-center w-full md:w-3 lg:w-2 h-full-content font-bold m-2">
				{props.childrenLeftPanel}
			</div>
			<div className="flex align-items-center justify-content-center lg:w-8 md:w-8 sm:w-full  h-full-content font-bold m-2">
				{props.children}
			</div>
			<div className="flex align-items-center justify-content-center w-full md:w-3 lg:w-2 h-full-content font-bold m-2">
				{props.childrenRightPanel}
			</div>
		</div>
	);
};

export default SemBody;
